import { insertInHash, removeFromHash } from "../../Common/Functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { UserState as SliceInterface } from "./Interface"


export const UserCreator = {
    login: createHandler({
        name: "user/login",
        method: "POST",
        route: "api/user/auth/login",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.loginStatus = "pending"
            state.loginMessage = "Logging in... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.loginStatus = "fulfilled"
            state.loginMessage = "Login success."
            state.current = action.payload.user
            state.users = insertInHash(state.users, action.payload.user, "id")
            state.token = action.payload.token
            state.refresh = action.payload.refresh
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.loginStatus = "rejected"
            state.loginMessage = action.payload.msg
            return state
        }
    }),
    loginAs: createHandler({
        name: "user/loginAs",
        method: "POST",
        route: "api/user/auth/log_in_as",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.loginStatus = "pending"
            state.loginMessage = "Logging in... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.loginStatus = "fulfilled"
            state.loginMessage = "Login success."
            state.current = action.payload.user
            state.users = insertInHash(state.users, action.payload.user, "id")
            state.token = action.payload.token
            state.refresh = action.payload.refresh
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.loginStatus = "rejected"
            state.loginMessage = action.payload.msg
            return state
        }
    }),
    refresh: createHandler({
        name: "user/refreshToken",
        method: "POST",
        route: "api/user/auth/refresh",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.loginStatus = "pending"
            state.loginMessage = "Logging in... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.loginStatus = "fulfilled"
            state.loginMessage = "Login success."
            state.current = action.payload.user
            state.users = insertInHash(state.users, action.payload.user, "id")
            state.token = action.payload.token
            state.refresh = action.payload.refresh
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.loginStatus = "rejected"
            state.loginMessage = action.payload.msg
            return state
        }
    }),
    register: createHandler({
        name: "user/register",
        method: "POST",
        route: "api/user/auth/register",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.registerStatus = "pending"
            state.registerMessage = "Registering user... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.registerStatus = "fulfilled"
            state.registerMessage = "User registered successfully."
            state.current = action.payload.user
            state.users = insertInHash(state.users, action.payload.user, "id")
            state.token = action.payload.token
            state.refresh = action.payload.refresh
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.registerStatus = "rejected"
            state.registerMessage = action.payload.msg
            return state
        }
    }),
    forgotPassword: createHandler({
        name: "user/forgotPassword",
        method: "POST",
        route: "/api/user/auth/forgot_password",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.resetPassword.emailStatus =  "pending"
            state.resetPassword.emailMessage =  "Sending email... Please wait."
            state.resetPassword.verifyStatus =  "idle"
            state.resetPassword.verifyMessage =  ""
            state.resetPassword.resetStatus =  "idle"
            state.resetPassword.resetMessage =  ""
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.resetPassword.emailStatus =  "fulfilled"
            state.resetPassword.emailMessage =  action.payload.msg
            state.resetPassword.verifyStatus =  "idle"
            state.resetPassword.verifyMessage =  ""
            state.resetPassword.resetStatus =  "idle"
            state.resetPassword.resetMessage =  ""
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.resetPassword.emailStatus =  "rejected"
            state.resetPassword.emailMessage =  action.payload.msg
            state.resetPassword.verifyStatus =  "idle"
            state.resetPassword.verifyMessage =  ""
            state.resetPassword.resetStatus =  "idle"
            state.resetPassword.resetMessage =  ""
            return state
        },
    }),
    verifyCode: createHandler({
        name: "user/verifyCode",
        method: "POST",
        route: "/api/user/auth/verify_code",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.resetPassword.verifyStatus =  "pending"
            state.resetPassword.verifyMessage =  "Verifying code... Please wait."
            state.resetPassword.resetStatus =  "idle"
            state.resetPassword.resetMessage =  ""
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.resetPassword.verifyStatus =  "fulfilled"
            state.resetPassword.verifyMessage =  action.payload.msg
            state.resetPassword.resetStatus =  "idle"
            state.resetPassword.resetMessage =  ""
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.resetPassword.verifyStatus =  "rejected"
            state.resetPassword.verifyMessage =  action.payload.msg
            state.resetPassword.resetStatus =  "idle"
            state.resetPassword.resetMessage =  ""
            return state
        },
    }),
    resetPassword: createHandler({
        name: "user/resetPassword",
        method: "POST",
        route: "/api/user/auth/reset_password",
        key: "user",
        skipAuth: true,
        pending: (state:SliceInterface, action) => {
            state.resetPassword.resetStatus =  "pending"
            state.resetPassword.resetMessage =  "Updating password... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.resetPassword.emailStatus =  "idle"
            state.resetPassword.emailMessage =  ""
            state.resetPassword.verifyStatus =  "idle"
            state.resetPassword.verifyMessage =  ""
            state.resetPassword.resetStatus =  "fulfilled"
            state.resetPassword.resetMessage =  action.payload.msg
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.resetPassword.emailStatus =  "idle"
            state.resetPassword.emailMessage =  ""
            state.resetPassword.verifyStatus =  "idle"
            state.resetPassword.verifyMessage =  ""
            state.resetPassword.resetStatus =  "rejected"
            state.resetPassword.resetMessage =  action.payload.msg
            return state
        },
    }),
    createUser : createHandler({
        name: "user/create",
        method: "POST",
        route: "/api/user/crud",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.createUserStatus = "pending"
            state.createUserMessage = "Creating user... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.createUserStatus = "fulfilled"
            state.createUserMessage = "User created succesfully."
            state.users = insertInHash(state.users, action.payload.user, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.createUserStatus = "rejected"
            state.createUserMessage = action.payload.msg
            return state
        }
    }),
    readUsers : createHandler({
        name: "user/read",
        method: "GET",
        route: "/api/user/crud",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.readUserStatus = "pending"
            state.readUserMessage = "Loading user(s)... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.readUserStatus = "fulfilled"
            state.readUserMessage = "User(s) loaded succesfully."
            if (action.payload?.user !== undefined) {
                state.users = insertInHash(state.users, action.payload.user, "id")
                if (action.payload.user.id === state.current.id) {
                    state.current = {...action.payload.user, allow_analytics: state.current?.allow_analytics}
                }
            }
            else if (action.payload.users.includes(null)) {
                state.users = action.payload.users
                for (const user of action.payload.users) {
                    if (user !== null && user.id === state.current.id) {
                        state.current = {...user, allow_analytics: state.current?.allow_analytics}
                    }
                }
            }
            else for (let user of action.payload.users) {
                if (user !== null && user.id === state.current.id) {
                    state.current = {...user, allow_analytics: state.current?.allow_analytics}
                }
                state.users = insertInHash(state.users, user, "id")
            }

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.readUserStatus = "rejected"
            state.readUserMessage = action.payload.msg
            return state
        }
    }),
    updateUser : createHandler({
        name: "user/update",
        method: "PUT",
        route: "/api/user/crud",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.updateUserStatus = "pending"
            state.updateUserMessage = "Updating user... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.updateUserStatus = "fulfilled"
            state.updateUserMessage = "User updated succesfully."
            if (action.payload.user.id === state.current.id) state.current = {...action.payload.user, allow_analytics: state.current?.allow_analytics}
            state.users = insertInHash(state.users, action.payload.user, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.updateUserStatus = "rejected"
            state.updateUserMessage = action.payload.msg
            return state
        }
    }),
    deleteUser : createHandler({
        name: "user/delete",
        method: "DELETE",
        route: "/api/user/crud",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.deleteUserStatus = "pending"
            state.deleteUserMessage = "Deleting user... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.deleteUserStatus = "fulfilled"
            state.deleteUserMessage = "User deleted."
            if (action.payload.user.id === state.current.id) state.current = {...action.payload.user, allow_analytics: state.current?.allow_analytics}
            state.users = removeFromHash(state.users, null, "archived_id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.deleteUserStatus = "rejected"
            state.deleteUserMessage = action.payload.msg
            return state
        }
    }),
    readUserTypes : createHandler({
        name: "user/user_types/read",
        method: "GET",
        route: "/api/general/user_types",
        key: "",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.userTypes.readStatus = "pending"
            state.userTypes.readMessage = "Loading user types... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.userTypes.readStatus = "fulfilled"
            state.userTypes.readMessage = "User types loaded succesfully."
            state.userTypes.types = action.payload.types
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.userTypes.readStatus = "rejected"
            state.userTypes.readMessage = action.payload.msg
            
            return state
        }
    }),


    createAddress : createHandler({
        name: "user/address/create",
        method: "POST",
        route: "/api/user/addresses",
        key: "address",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.address.createStatus = "pending"
            state.address.createMessage = "Creating address... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.address.createStatus = "fulfilled"
            state.address.createMessage = "Address created successfully... Please wait."
            state.address.addresses = insertInHash(state.address.addresses, action.payload.address, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.address.createStatus = "rejected"
            state.address.createMessage = action.payload.msg
            return state
        }
    }),
    readAddresses : createHandler({
        name: "user/address/read",
        method: "GET",
        route: "/api/user/addresses",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.address.readStatus = "pending"
            state.address.readMessage = "Loading addresses.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.address.readStatus = "fulfilled"
            state.address.readMessage = "Addresses loaded succesfully."
            for (const a of action.payload.addresses) state.address.addresses = insertInHash(state.address.addresses, a, "id")

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.address.readStatus = "rejected"
            state.address.readMessage = action.payload.msg
            return state
        }
    }),
    updateAddress : createHandler({
        name: "user/address/update",
        method: "PUT",
        route: "/api/user/addresses",
        key: "address",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.address.updateStatus = "pending"
            state.address.updateMessage = "Updating address... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.address.updateStatus = "fulfilled"
            state.address.updateMessage = "Address updated succesfully."
            state.address.addresses = insertInHash(state.address.addresses, action.payload.address, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.address.updateStatus = "rejected"
            state.address.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteAddress : createHandler({
        name: "user/address/delete",
        method: "DELETE",
        route: "/api/user/addresses",
        key: "address",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.address.deleteStatus = "pending"
            state.address.deleteMessage = "Deleting address... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.address.deleteStatus = "fulfilled"
            state.address.deleteMessage = "Address deleted."
            state.address.addresses = state.address.addresses.filter(a => a.id !== action.payload.address_id)
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.address.deleteStatus = "rejected"
            state.address.deleteMessage = action.payload.msg
            return state
        }
    }),
    readCountries: createHandler({
        name: 'user/countries/read',
        method: "GET",
        route: "/api/general/countries",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.countries.readStatus = "pending"
            state.countries.readMessage = "Loading countries.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.countries.readStatus = "fulfilled"
            state.countries.readMessage = "Countries loaded succesfully."
            if (action.payload.countrys.includes(null)) state.countries.countries = action.payload.countrys
            else for (const c of action.payload.countrys) state.countries.countries = insertInHash(state.countries.countries, c, "id")

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.countries.readStatus = "rejected"
            state.countries.readMessage = action.payload.msg
            return state
        }
    }),
    readDemoUsers: createHandler({
        name: 'user/demo_users/read',
        method: "GET",
        route: "/api/general/register_demo_users",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.demoUsers.readStatus = "pending"
            state.demoUsers.readMessage = "Loading users.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.demoUsers.readStatus = "fulfilled"
            state.demoUsers.readMessage = "Users loaded succesfully."
            state.demoUsers.users = action.payload.emails
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.demoUsers.readStatus = "rejected"
            state.demoUsers.readMessage = action.payload.msg
            return state
        }
    }),
    updateDemoUsers: createHandler({
        name: 'user/demo_users/update',
        method: "PUT",
        route: "/api/general/update_demo_users",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.demoUsers.updateStatus = "pending"
            state.demoUsers.updateMessage = "Updating user.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.demoUsers.updateStatus = "fulfilled"
            state.demoUsers.updateMessage = "User updated succesfully."
            state.demoUsers.users = [...state.demoUsers.users.filter(u => u.id !== action.payload.mail.id), action.payload.mail]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.demoUsers.updateStatus = "rejected"
            state.demoUsers.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteDemoUsers: createHandler({
        name: 'user/demo_users/delete',
        method: "DELETE",
        route: "/api/general/delete_demo_users",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.demoUsers.deleteStatus = "pending"
            state.demoUsers.deleteMessage = "Deleting user.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.demoUsers.deleteStatus = "fulfilled"
            state.demoUsers.deleteMessage = "User deleted succesfully."
            state.demoUsers.users = state.demoUsers.users.filter(u => u.id !== action.payload.deleted_mail.id)
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.demoUsers.deleteStatus = "rejected"
            state.demoUsers.deleteMessage = action.payload.msg
            return state
        }
    }),
    readIndependentUsers: createHandler({
        name: 'user/independent_users/read',
        method: "GET",
        route: "/api/user/independent_user/crud",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            if (state.independentUser === undefined) {
                state.independentUser = { sessions: [], accounts: [], readStatus: "pending", readMessage: "", updateStatus: "idle", updateMessage: ""}
            }
            state.independentUser.readStatus = "pending"
            state.independentUser.readMessage = "Loading users.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.independentUser.readStatus = "fulfilled"
            state.independentUser.readMessage = "Users loaded succesfully."
            state.independentUser.sessions = action.payload.sessions
            state.independentUser.accounts = action.payload.accounts
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.independentUser.readStatus = "rejected"
            state.independentUser.readMessage = action.payload.msg
            return state
        }
    }),
    updateIndependentUsers: createHandler({
        name: 'user/independent_users/update',
        method: "PUT",
        route: "/api/user/independent_user/crud",
        key: "user",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.independentUser.updateStatus = "pending"
            state.independentUser.updateMessage = "Updating user.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.independentUser.updateStatus = "fulfilled"
            state.independentUser.updateMessage = "User updated succesfully."
            state.independentUser.accounts = [...state.independentUser.accounts.filter(u => u.account_id !== action.payload.account.account_id), action.payload.account]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.independentUser.updateStatus = "rejected"
            state.independentUser.updateMessage = action.payload.msg
            return state
        }
    }),
}

export const [UserActions, UserReducers] = buildFromCreator(UserCreator)