import { IonButton } from "@ionic/react"
import { useState } from "react"
import { MdAddBox } from "react-icons/md"
import { Organization } from "../../../Common/Interfaces/Entities"
import { UserState } from "../../../Redux/User/Interface"
import Search from "./Search"
import UserCard from "./UserCard"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../../Redux/Store"
import Translate from "../../../components/Translate"


const OrganizationUsersContainer: React.FC<{ organization: Organization }> = ({
    organization
}) => {
    const [search, setSearch] = useState<string>("")
    const [showMore, setShowMore] = useState<Boolean>(false)
    const userReducer:UserState = useSelector((state:RootState) => state.user)

    const users = userReducer.users.filter(u =>
        (u !== undefined && u !== null && u.organization_id === organization.id && u.archived !== 1) &&
        (search === "" || (u.username.toLowerCase().startsWith(search.toLowerCase())) ||
            (u.username).toLowerCase().startsWith(search.toLowerCase()))
    ).sort((a, b) => a.type_id < b.type_id ? 1 : -1)
    const firstFive = users.slice(0, 5)

    return (
        <div className="p-8 bg-white rounded-lg shadow mb-4">
            <span className="flex justify-between items-center">
                <h3 className="text-slate-700 font-medium text-lg"><Translate label="Organization Users" /></h3>
                <NavLink className="text-sm rounded hover:shadow-md bg-cythero-primary text-white cursor-pointer flex items-center gap-2 p-1.5 font-bold" to="/users/create"><Translate label="ADD NEW" /> <MdAddBox className="text-lg" /></NavLink>
            </span>
            <Search search={search} setSearch={setSearch} />
            {showMore === true ?
                users.map(u => <UserCard key={u.id} user={u} userReducer={userReducer} />) :
                firstFive.map(u => <UserCard key={u.id} user={u} userReducer={userReducer} />)
            }
            {users.length <= 5 ? null : <IonButton className="mt-5" onClick={e => setShowMore(!showMore)}><Translate label={showMore === true ? "Show Less" : "Show More"} /></IonButton>}
        </div>
    )
}

export default OrganizationUsersContainer