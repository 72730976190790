import React, { Fragment } from "react"
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Route, Switch } from "react-router-dom"
import { IonRouterOutlet } from "@ionic/react"
import AuthContainer from "../pages/Auth/AuthContainer"
import { useSelector } from "react-redux"
import { RootState } from "../Redux/Store"
import Nav from "../components/Nav/Nav"
import Devices from "../pages/Devices/Devices"
import ApplicationsContainer from "../pages/Applications/ApplicationsContainer/ApplicationsContainer"
import ApplicationScreen from "../pages/Applications/ApplicationPage/ApplicationScreen"
import CreateApplication from "../pages/Applications/CreateApplication/CreateApplication"
import BrowseUsers from "../pages/Users/BrowseUsers/BrowseUsers"
import CreateUser from "../pages/Users/CreateUser/CreateUser"
import EditUser from "../pages/Users/EditUser/EditUser"
import Releases from "../pages/Applications/Releasees/Releases"
import EditRelease from "../pages/Applications/Releasees/EditRelease"
import CreateRelease from "../pages/Applications/Releasees/CreateRelease"
import Dlcs from "../pages/Applications/DLCs/Dlcs"
import EditDlc from "../pages/Applications/DLCs/EditDlc"
import CreateDlc from "../pages/Applications/DLCs/CreateDlc"
import ProfilePageContainer from "../pages/ProfilePage/ProfilePageContainer"
import OrganizationContainer from "../pages/Organizations/OrganizationContainer"
import BrowseOrganizations from "../pages/Organizations/BrowseOrganizations/BrowseOrganizations"
import EditApplication from "../pages/Applications/EditApplication/EditApplication"
import CartContainer from "../pages/Cart/CartContainer"
import ManageBranding from "../pages/Applications/Branding/ManageBranding"
import CreateApplicationPackages from "../pages/Applications/ApplicationPage/ApplicationPackages/CreateApplicationPackage"
import BrowseApplicationPackages from "../pages/Applications/ApplicationPage/ApplicationPackages/BrowseApplicationPackages"
import DashboardContainer from "../pages/Dashboard/DashboardContainer"
import SettingsContainer from "../pages/Settings/SettingsContainer"
import EditApplicationPackage from "../pages/Applications/ApplicationPage/ApplicationPackages/EditApplicationPackage"
import AnalyticsContainer from "../pages/Analytics/AnalyticsContainer"
import PurchasesContainer from "../pages/PurchaseHistory/PurchasesContainer"
import ApplicationPage from "../pages/ApplicationPages/ApplicationPage"
import PurchasePage from "../pages/PurchaseHistory/PurchasePage"
import Container from "../pages/DemoOrganizations/Container"
import RegisteredEmailsContainer from "../pages/RegisteredEmails/RegisteredEmailsContainer"
import LanguagesMenagement from "../pages/languages/LanguagesMenagement"
import IndependentUsers from "../pages/IndependentUsers/IndependentUsers"

const RootRouter: React.FC = props => {

    const userReducer = useSelector((state: RootState) => state.user)

    return (
        <IonReactRouter>
            <IonRouterOutlet>
                {userReducer.current === null ? <Fragment>
                    <Redirect to="/auth/login" />
                    <Route path="/auth/:option" component={AuthContainer} />
                </Fragment> : <Fragment>
                    <Nav>
                        <Switch>
                            <Route path="/dashboard/:id?" component={DashboardContainer} />
                            <Route path="/reports/:id" component={AnalyticsContainer} />
                            <Route path="/devices" component={Devices} />

                            <Route exact path="/organization/browse" component={BrowseOrganizations} />
                            <Route exact path="/organization/:option" component={OrganizationContainer} />
                            <Route exact path="/demo_organizations" component={Container} />
                            <Route exact path="/registered_emails" component={RegisteredEmailsContainer} />
                            <Route exact path="/independant_users" component={IndependentUsers} />

                            <Route exact path="/languages" component={LanguagesMenagement} />

                            <Route exact path="/application_dashboard/:org_id/:app_id" component={ApplicationPage} />

                            <Route exact path="/applications" component={ApplicationsContainer} />
                            <Route exact path="/applications/create" component={CreateApplication} />
                            <Route exact path="/applications/edit/:id" component={EditApplication} />

                            <Route exact path={"/applications/create_package/:id"} component={CreateApplicationPackages} />
                            <Route exact path={"/applications/edit_package/:id"} component={EditApplicationPackage} />
                            <Route exact path={"/applications/packages/:id"} component={BrowseApplicationPackages} />

                            <Route exact path="/applications/releases/:id" component={Releases} />
                            <Route exact path="/applications/release/edit/:id" component={EditRelease} />
                            <Route exact path="/applications/releases/create/:id" component={CreateRelease} />

                            <Route exact path="/applications/dlcs/:id" component={Dlcs} />
                            <Route exact path="/applications/dlc/edit/:id" component={EditDlc} />
                            <Route exact path="/applications/dlcs/create/:id" component={CreateDlc} />
                            <Route exact path="/manage_branding/:id" component={ManageBranding} />

                            <Route exact path="/applications/:id" component={ApplicationScreen} />
                            <Route path="/purchases/" component={PurchasesContainer} />
                            <Route path="/purchase/:order_number" component={PurchasePage} />
                            <Route exact path="/users" component={BrowseUsers} />
                            <Route path="/user/profile/:option" component={ProfilePageContainer} />
                            <Route path="/users/create" component={CreateUser} />
                            <Route path="/users/edit/:id" component={EditUser} />
                            
                            <Route path="/cart/" component={CartContainer} />
                            <Route path="/settings" component={SettingsContainer} />

                            <Redirect to="/dashboard" />
                        </Switch>
                    </Nav>
                </Fragment>
                }
            </IonRouterOutlet>
        </IonReactRouter>
    )
}

export default RootRouter